'use client';

import { useEffect } from 'react';
import { useCookie } from 'react-use';
import { usePathname } from 'next/navigation';

import type { UserRole } from '@zealy/utils';
import { Popup } from '@zealy/design-system';
import { useAuthenticatedUser } from '@zealy/queries';

import { useCommunitiesFlags } from '#context/FeatureFlags';

import { usePopup } from './PopupProvider';

interface FlagValue {
  id: string;
  title: string;
  description?: string;
  cta?: string;
  img?: string;
  video?: string;
  link?: string;
  role?: UserRole;
  excludedUrlWords?: string[];
}

export const FeatureFlagPopup = () => {
  const flags = useCommunitiesFlags(['popup']);
  const user = useAuthenticatedUser();
  const pathname = usePathname();

  const { isOpen, onOpenChange } = usePopup({
    id: 'ff_popup',
    priority: 3,
  });

  const { enabled, value } = flags.popup;

  let title: FlagValue['title'] = '';
  let description: FlagValue['description'];
  let cta: FlagValue['cta'];
  let img: FlagValue['img'];
  let link: FlagValue['link'];
  let role: FlagValue['role'];
  let id: FlagValue['id'] = '';
  let video: FlagValue['video'];
  let excludedUrlWords: FlagValue['excludedUrlWords'];
  if (value) {
    try {
      const parsedValue = JSON.parse(value as string) as FlagValue;

      title = parsedValue.title ?? '';
      description = parsedValue.description ?? '';

      id = parsedValue.id || parsedValue.title;

      cta = parsedValue.cta;
      img = parsedValue.img;
      link = parsedValue.link;
      role = parsedValue.role;
      video = parsedValue.video;
      excludedUrlWords = Array.isArray(parsedValue.excludedUrlWords)
        ? parsedValue.excludedUrlWords
        : undefined;
    } catch (error) {
      console.error('Error parsing popup value', error);
    }
  }

  const [seenFlag, setSeenFlag] = useCookie(`popup:${id}`);

  const hasValue = Boolean(value);
  const hasNotSeenFlag = !seenFlag;
  const meetsRoleRequirement =
    !role || (user.data && 'role' in user.data && user.data.role === role);
  const urlMatchesExcludedWords = excludedUrlWords?.some(word => pathname.includes(word));

  const showPopup =
    enabled && hasNotSeenFlag && hasValue && meetsRoleRequirement && !urlMatchesExcludedWords;

  useEffect(() => {
    if (showPopup) {
      onOpenChange(true);
    }
    return () => {
      onOpenChange(false);
    };
  }, [showPopup, onOpenChange]);

  const setSeen = () => {
    setSeenFlag('true', {
      expires: 7,
    });
    onOpenChange(false);
  };

  const onClick = () => {
    setSeen();
    if (link) window.open(link, '_blank');
  };

  return (
    <Popup
      className="dark"
      open={isOpen}
      onOpenChange={setSeen}
      contentProps={{
        onPointerDownOutside: e => {
          e.stopPropagation();
          e.preventDefault();
        },
      }}
      {...{ title, description, cta, img, link, onClick, video }}
    />
  );
};
