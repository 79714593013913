import { useMutation } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { api } from '../services';
export const useExportRewardWinners = (subdomain = getSubdomain()) => {
    return useMutation({
        mutationFn: ({ questId, rewardId }) => api.post(`/communities/${subdomain}/claimed-quests/export-reward-winners`, {
            body: {
                questId,
                rewardId,
            },
        }),
    });
};
