import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getSubdomain } from '@zealy/utils';
import { usersKeys } from '../users.keys';
import { switchUserAccount } from '../users.service';
/**
 * Switches a user account (currently only Twitter) from one user to another
 */
export const useSwitchUserAccount = () => {
    const subdomain = getSubdomain();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: switchUserAccount,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: usersKeys.user('me', subdomain),
            });
        },
    });
};
