'use client';

import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import NextLink from 'next/link';

import { Button } from '@zealy/design-system';

import { CREATE_COMMUNITY_PATH } from '#constants/routes';
import { SearchInput } from '#context/Search';

import type { NavbarLinkMapper } from './Navbar.const';
import { LogoFull } from '../Logo';
import { AuthNav } from './AuthNav';
import { MobileNavMenu } from './MobileNavMenu';
import { NAVBAR_LINKS } from './Navbar.const';
import { NavMenu } from './NavMenu';

export const Navbar = ({ showBorder = true, showSearch = true }) => {
  const t = useTranslations('navbar');

  return (
    <div
      className={cx(
        'flex items-center justify-between w-full top-0 z-30 left-0 right-0 mx-auto h-1200 col-span-4 md:col-span-12',
        showBorder ? 'fixed px-200 md:px-600' : 'px-300 md:px-400 sticky',
      )}
    >
      <div
        className={cx(
          'flex items-center justify-between w-full top-0 z-30 left-0 right-0 mx-auto h-1200 col-span-4 md:col-span-12 max-w-landing',
          showBorder
            ? 'border-component-tertiary shadow-lg bg-primary md:bg-stars bg-blend-screen border-component-md border-t-0 rounded-b-component-xl px-200 md:px-300'
            : '',
        )}
      >
        <div className="flex flex-row">
          <LogoFull href="/" />
          <NavigationMenu.Root className="relative z-[1] lg:flex hidden flex-1 ml-500 w-[400px]">
            <NavigationMenu.List className="center m-0 flex list-none gap-100">
              {Object.keys(NAVBAR_LINKS).map(key => (
                <NavMenu key={key} title={key as keyof NavbarLinkMapper} />
              ))}
              {/* <Button variant="ghost" className="hidden md:flex" as={NextLink} href="/changelog">
                {t('changelog')}
              </Button> */}
            </NavigationMenu.List>

            <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-start">
              <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative mt-[10px] h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-component-md bg-tertiary border-component-secondary shadow-lg transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
            </div>
          </NavigationMenu.Root>
        </div>
        {showSearch && (
          <div className="flex flex-row">
            <SearchInput />
          </div>
        )}
        <div className="flex gap-100 items-center">
          <Button
            variant="ghost"
            className="hidden md:flex"
            as={NextLink}
            href={CREATE_COMMUNITY_PATH}
          >
            {t('create-community')}
          </Button>

          <AuthNav
            authChildren={
              <Button as={NextLink} href="/my-communities" variant="muted">
                {t('my-communities')}
              </Button>
            }
          >
            <>
              <Button as={NextLink} href="/login" variant="muted">
                {t('login')}
              </Button>
              <Button as={NextLink} href="/signup" className="hidden md:flex">
                {t('sign-up')}
              </Button>
            </>
          </AuthNav>

          <MobileNavMenu />
        </div>
      </div>
    </div>
  );
};
