import { DialogClose } from '@radix-ui/react-dialog';
import React from 'react';
import { useTranslations } from 'next-intl';

import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerTrigger,
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from '@zealy/design-system';

import type { ModalProps } from '#components/Modal';
import { Modal } from '#components/Modal';
import { useDebouncedValue } from '#hooks/useDebouncedValue';
import { useDidMount } from '#hooks/useDidMount';
import { useIsMobile } from '#hooks/useIsMobile';

export interface ResponsivePopoverProps extends ModalProps {
  trigger?: React.ReactElement;
  popoverProps?: React.ComponentProps<typeof PopoverContent>;
  asModal?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
  showActions?: boolean;
  drawerClassName?: string;
  saveLabel?: React.ReactNode;
  isLoading?: boolean;
}

export const ResponsivePopover = ({
  children,
  trigger,
  popoverProps,
  asModal,
  onSave,
  onCancel,
  showActions,
  drawerClassName,
  saveLabel,
  isLoading,
  ...props
}: ResponsivePopoverProps) => {
  const t = useTranslations('common');
  const isMobile = useIsMobile();

  const isMobileDebounced = useDebouncedValue(isMobile, 3000);

  const didMount = useDidMount();

  if (!didMount) return null;

  if (isMobileDebounced)
    return (
      <Drawer {...props}>
        {trigger && <DrawerTrigger asChild>{trigger}</DrawerTrigger>}

        <DrawerContent>
          {showActions && (
            <div className="w-full flex gap-100 justify-between -mt-600">
              <DrawerClose asChild>
                <Button size="md" variant="ghost" onClick={onCancel} mutedText>
                  {t('cancel')}
                </Button>
              </DrawerClose>

              <Button size="md" variant="ghost" onClick={onSave} color="cta">
                {saveLabel ?? t('save')}
              </Button>
            </div>
          )}

          {children}
        </DrawerContent>
      </Drawer>
    );

  if (asModal)
    return (
      <Modal trigger={trigger} {...props} className="min-w-[300px] p-300">
        {children}

        {showActions && (
          <div className="w-full flex justify-end pt-200 gap-100 border-t ">
            <DialogClose asChild>
              <Button variant="ghost" onClick={onCancel} mutedText>
                {t('cancel')}
              </Button>
            </DialogClose>
            <Button onClick={onSave} loading={isLoading}>
              {saveLabel ?? t('save')}
            </Button>
          </div>
        )}
      </Modal>
    );

  return (
    <Popover {...props}>
      <PopoverTrigger asChild>{trigger}</PopoverTrigger>
      <PopoverContent
        align="end"
        variant="secondary"
        sideOffset={8}
        className="w-[230px]"
        {...popoverProps}
      >
        {children}

        {showActions && (
          <div className="w-full flex justify-end pt-200 gap-100 border-t ">
            <PopoverClose asChild>
              <Button variant="ghost" onClick={onCancel} mutedText>
                {t('cancel')}
              </Button>
            </PopoverClose>
            <Button onClick={onSave} loading={isLoading}>
              {saveLabel ?? t('save')}
            </Button>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
};
