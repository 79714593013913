'use client';

import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { cx } from 'class-variance-authority';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import NextLink from 'next/link';

import type { CommunityMember, User } from '@zealy/queries';
import {
  Button,
  DropdownContent,
  DropdownGroup,
  DropdownItem,
  DropdownMenu,
  DropdownPortal,
  DropdownSeparator,
  DropdownTrigger,
  ProgressBarAnatomy as P,
} from '@zealy/design-system';
import {
  BookLine,
  CalendarLine,
  CallAddLine,
  CommentsLine,
  GearLine,
  LogOutLine,
  MoreHorizFilled,
  UserLine,
} from '@zealy/icons';

import { useNavbarActions } from '#components/Navbar/Navbar.helpers';
import { DOCUMENTATION_URL } from '#constants';

import { styles } from '../Sidebar.styles';

export interface UserDropdownProps {
  user: User | CommunityMember;
  subdomain: string;
}

export const UserDropdown = ({ user, subdomain }: UserDropdownProps) => {
  const t = useTranslations('sidebar');

  const intercom = useIntercom();

  const { signOut } = useNavbarActions();

  return (
    <DropdownMenu name="user-dropdown" size="sm">
      <DropdownTrigger asChild>
        <Button
          data-part="usermenu"
          className={cx(styles.banner.content.menu.trigger())}
          size="sm"
          variant={'muted'}
          leftIcon={<MoreHorizFilled />}
          data-testid="user-menu-trigger"
          aria-label="More"
        />
      </DropdownTrigger>
      <DropdownPortal>
        <DropdownContent
          className="min-w-[200px] z-50"
          sideOffset={16}
          alignOffset={-16}
          side="top"
          align="end"
        >
          <div className="p-50 flex flex-col gap-50">
            {'role' in user && user.role && (
              <DropdownItem>
                <UserLine />
                <NextLink href={`/cw/${subdomain}/users/${user?.id}`} className="w-full">
                  {t('settings.profile')}
                </NextLink>
              </DropdownItem>
            )}
            <DropdownItem>
              <GearLine />
              <NextLink href={`/cw/${subdomain}/settings/profile`} className="w-full">
                {t('settings.settings')}
              </NextLink>
            </DropdownItem>
            <DropdownSeparator />
            <Link href={DOCUMENTATION_URL} className="w-full">
              <DropdownItem leftIcon={<BookLine />}>{t('settings.documentation')}</DropdownItem>
            </Link>
            <DropdownItem onClick={intercom.show} leftIcon={<CommentsLine />}>
              {t('settings.feedback')}
            </DropdownItem>

            {/* <Link href="/changelog" className="w-full">
              <DropdownItem leftIcon={<CalendarLine />}>{t('settings.changelog')}</DropdownItem>
            </Link> */}
          </div>

          <DropdownSeparator />
          <DropdownGroup type="destructive">
            <DropdownItem onClick={signOut} leftIcon={<LogOutLine />}>
              {t('settings.logout')}
            </DropdownItem>
          </DropdownGroup>
        </DropdownContent>
      </DropdownPortal>
    </DropdownMenu>
  );
};
