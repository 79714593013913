import { DOCUMENTATION_URL } from '#constants';
import { CREATE_COMMUNITY_PATH } from '#constants/routes';

export const FOOTER_LINKS = {
  learn: {
    title: 'menu.learn.title',
    items: [
      {
        path: 'https://blog.zealy.io/',
        label: 'menu.learn.items.blog',
      },
      {
        path: DOCUMENTATION_URL,
        label: 'menu.learn.items.documentation',
      },
      {
        path: 'https://docs.zealy.io/',
        label: 'menu.resources.items.api-docs',
      },
    ],
  },
  'get-started': {
    title: 'menu.get-started.title',
    items: [
      {
        path: '/signup',
        label: 'menu.get-started.items.sign-up',
      },
      {
        path: '/login',
        label: 'menu.get-started.items.login',
      },
      {
        path: CREATE_COMMUNITY_PATH,
        label: 'menu.get-started.items.create-community',
      },
      {
        path: 'https://whv9i6ohysz.typeform.com/to/u8bq19dO',
        label: 'menu.get-started.items.partnerships',
      },
    ],
  },
  resources: {
    title: 'menu.resources.title',
    items: [
      {
        path: '/about',
        label: 'menu.resources.items.about',
      },
      // {
      //   path: '/use-cases',
      //   label: 'menu.resources.items.use-cases',
      // },
      // {
      //   path: '/roadmap',
      //   label: 'menu.resources.items.roadmap',
      // },
      // {
      //   path: '/careers',
      //   label: 'menu.resources.items.careers',
      // },
      {
        path: '/?support=true',
        label: 'menu.resources.items.contact-support',
      },
      {
        path: '/consent-preferences',
        label: 'menu.resources.items.cookie-preferences',
      },
    ],
  },
} as const;

export type FooterLinkMapper = typeof FOOTER_LINKS;

export const TWITTER_URL = 'https://twitter.com/zealy_io';
export const DISCORD_URL = 'https://discord.gg/5DZkYCNyes';
export const TELEGRAM_URL = 'https://t.me/+Il64ZrLqrfJmNmMx';
