import { z } from 'zod';
import { mutation } from '../../utils';
export const reorderQuests = mutation({
    method: 'PATCH',
    path: '/v2/reorder',
    body: z.object({
        questId: z.string(),
        hoverId: z.string(),
    }),
    responses: {
        200: z.array(z.object({
            id: z.string(),
            categoryId: z.string(),
            position: z.number(),
        })),
    },
});
